<template>
  <div class="container-fluid bg-light" v-if="signedIn">
    <b-row class="pt-4">
      <b-col>
        <b-container>
          <b-row>
            <b-col>
              <search-form />
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-container>
          <b-row>
            <b-col md="12" :lg="permissions.readInsights ? 6 : 12" class="bg-kpmg-dark-blue py-4 mt-4" v-if="newUser">
              <h5 class="card-title font-kpmg font-size-44"><router-link class="text-white" :to="{ name: 'NewsAdvancedFilter', query: { topic: this.favTopicIds } }">Personal headlines</router-link></h5>
              <b-card
                title="Welcome"
                tag="article"
                class="mb-2"
                style="height: 75%;"
              >
                <b-card-text>
                  It looks like you're a new user or on a new device.
                </b-card-text>
                <b-card-text>
                  To help personalise your experience we recommend selecting some topics to follow. This will allow us to provide you with the news and information you care about most.
                </b-card-text>
                <b-card-text>
                  Click the button below to get started.
                </b-card-text>
                <b-button v-b-modal.topicSelection @click="loadTopics" variant="primary" block class="mb-4">Select Topics</b-button>
              </b-card>
            </b-col>
            <b-col md="12" :lg="permissions.readInsights ? 6 : 12" class="text-white bg-kpmg-dark-blue py-4 mt-4" v-if="!loading.fav && !newUser">
              <h5 class="card-title font-kpmg font-size-44"><router-link class="text-white" :to="{ name: 'NewsAdvancedFilter', query: { topic: this.favTopicIds } }">Personal headlines</router-link></h5>
              <div v-for="(regtopicName, index) in regTopics" :key="index" class="mb-2">
                <h5> {{ regtopicName }} </h5>
                <ul>
                  <li v-for="(headline, innerIndex) in personalHeadlines(regtopicName)" :key="innerIndex">
                    <router-link class="text-white" :to="{ name: 'NewsItem', params: { id: headline.id}}" >{{headline.name}} <small>  - {{headline.date}} - {{headline.publisher}}</small></router-link>
                  </li>
                </ul>
              </div>
              <div>
                <router-link class="text-white" :to="{ name: 'NewsAdvancedFilter', query: { topic: this.favTopicIds } }" ><strong>... see more</strong></router-link>
              </div>
              <div class="text-right">
                  <b-button v-b-modal.topicSelection @click="loadTopics">update your topics</b-button>
              </div>
            </b-col>
            <b-col md="12" lg="6" class="text-center" v-if="loading.fav">
              <h3>loading personal topics</h3>
              <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
            </b-col>
            <b-col md="12" :lg="permissions.readInsights ? 6 : 12" class="text-white bg-kpmg-blue py-4 mt-4" v-if="!loading.startpage && permissions.readInsights">
              <h5 class="card-title font-kpmg font-size-44"><router-link class="text-white" :to="{ name: 'Articles' }">KPMG Insights</router-link></h5>
              <li v-for="item in articles" :key="'startnews' + item.id" class="mb-2 ml-4">
                <router-link class="text-white" :to="{ name: 'Article', params: { id: item.id}}" ><strong>{{item.title}}</strong> - {{item.approved_at}}</router-link>
              </li>
              <div>
                <router-link class="text-white" :to="{ name: 'Articles'}" ><strong>... see more</strong></router-link>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-white bg-kpmg-cobalt-blue py-4">
              <b-row>
                <b-col>
                  <h5 class="card-title font-kpmg font-size-44 text-center">Most popular</h5>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" lg="4">
                  <h4 class="text-center"><b-link class="text-white" :to="{ name: 'Articles' }">KPMG Insights</b-link></h4>
                  <li v-for="item in articleResults" :key="'startnews' + item.id" class="mb-2 ml-4">
                    <router-link class="text-white" :to="{ name: 'Article', params: { id: item.id}}">{{item.title}}</router-link>
                  </li>
                </b-col>
                <b-col md="12" lg="4">
                  <h4 class="text-center"><b-link class="text-white" :to="{ name: 'NewsAdvancedFilter' }">News</b-link></h4>
                  <li v-for="item in publicationResults" :key="'startnews' + item.id" class="mb-2 ml-4">
                    <router-link class="text-white" :to="{ name: 'NewsItem', params: { id: item.id}}" >{{item.name}}</router-link>
                  </li>
                </b-col>
                <b-col>
                  <h4 md="12" lg="4" class="text-center"><b-link class="text-white" :to="{ name: 'Topics' }">Topics</b-link></h4>
                  <li v-for="item in topicResults" :key="'startnews' + item.id" class="mb-2 ml-4">
                    <router-link class="text-white" :to="{ name: 'Topic', params: { id: item.id}}" >{{item.name}}</router-link>
                  </li>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="loading.startpage">
            <b-col class="text-center">
              <h3>loading start page</h3>
              <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
            </b-col>
          </b-row>
          <!--
          <b-row class="bg-white py-4" v-if="!loading.startpage && $config.BUILD  !== 'externalkpmgarc'">
            <b-col md="12" lg="7">
              <h5 class="kpmg">Headlines</h5>
              <div v-for="item in startpage.newsregtopic" :key="'startnews' + item.id" class="mb-2">
                <a :href="item.link" target="_blank"><i class="fa fa-external-link" aria-hidden="true"></i></a>&nbsp;
                <router-link :to="{ name: 'NewsItem', params: { id: item.id}}" ><strong>{{item.publisher}}</strong> {{item.name}}</router-link>
              </div>
              <div>
                <router-link :to="{ name: 'News' }" ><strong>...all news live feed</strong></router-link> |
                <router-link :to="{ name: 'NewsAdvancedFilter' }"><strong>filter news</strong></router-link>
              </div>
            </b-col>
            <b-col md="12" lg="5">
              <h5 class="kpmg">Upcoming events</h5>
              <div v-for="item in startpage.events" :key="'startevents' + item.id" class="mb-2">
                <strong>{{item.date}}</strong> {{item.name}}
              </div>
              <div>
                <router-link :to="{ name: 'Events' }" ><strong>...all events</strong></router-link>
              </div>
            </b-col>
          </b-row>
          -->
          <b-row v-if="!loading.startpage && $config.BUILD  !== 'externalkpmgarc'">
            <b-col md="12" lg="8"  class="text-white bg-kpmg-blue py-4">
              <h4 class="card-title font-kpmg font-size-44"><router-link class="text-white" :to="{ name: 'Types' }">Types</router-link></h4>
              <span v-for="item in startpage.tags" :key="'tags' + item.id" class="mb-2">
                <router-link class="text-white" :to="{ name: 'NewsTag', params: { id: item.id}}" >{{item.name}}</router-link> |
              </span>
            </b-col>
            <b-col md="12" lg="4" class="text-white bg-kpmg-light-purple py-4">
              <h4 class="card-title font-kpmg font-size-44"><router-link class="text-white" :to="{ name: 'Signals' }">Signals</router-link></h4>
              <p class="card-text">Regulatory intelligence</p>
              <p class="card-text" v-if="$config.BUILD  == 'full'"><router-link class="text-white" :to="{ name: 'Keywords' }">Keywords AI - experimental</router-link></p>
            </b-col>
          </b-row>
          <b-row v-if="!loading.startpage && $config.BUILD  !== 'externalkpmgarc'">
            <b-col md="12" lg="4" class="bg-kpmg-cobalt-blue text-white py-4">
              <h4 class="card-title font-kpmg font-size-44">
                <router-link class="text-white" :to="{ name: 'News' }" >Live feed</router-link> |
                <router-link class="text-white" :to="{ name: 'NewsAdvancedFilter' }">Filter</router-link>
              </h4>
              <p class="card-text">News from regulatory sources</p>
              <h4><router-link class="text-white" :to="{ name: 'RecentActivity'}">Recently tagged news</router-link></h4>
            </b-col>
            <b-col md="12" lg="4" class="bg-kpmg-dark-blue text-white py-4">
              <h4 class="card-title font-kpmg font-size-44"><router-link class="text-white" :to="{ name: 'Events' }" >Events</router-link></h4>
              <p class="card-text">Timeline of regulatory events</p>
            </b-col>
            <b-col md="12" lg="4" class="bg-kpmg-purple text-white py-4">
              <h4 class="card-title font-kpmg font-size-44"><router-link class="text-white" :to="{ name: 'Topics' }" >Topics</router-link></h4>
              <p class="card-text">Key regulations and topics</p>
            </b-col>
          </b-row>
          <b-row v-if="!loading.startpage && $config.BUILD  === 'externalkpmgarc'">
            <b-col md="12" lg="6" class="bg-kpmg-medium-blue text-white py-4">
              <h4 class="card-title font-kpmg font-size-44">
                <router-link class="text-white" :to="{ name: 'News' }" >Live feed</router-link> |
                <router-link class="text-white" :to="{ name: 'NewsAdvancedFilter' }">Filter</router-link>
              </h4>
              <p class="card-text">News from regulatory sources</p>
              <h4><router-link class="text-white" :to="{ name: 'RecentActivity'}">Recently tagged news</router-link></h4>
            </b-col>
            <b-col md="12" lg="6" class="bg-kpmg-purple text-white py-4">
              <h4 class="card-title font-kpmg font-size-44"><router-link class="text-white" :to="{ name: 'Topics' }" >Topics</router-link></h4>
              <p class="card-text">Key regulations and topics</p>
            </b-col>
          </b-row>
          <b-row v-if="!loading.startpage">
            <b-col md="12" lg="6" class="bg-kpmg-pacific-blue text-white py-4">
              <h4 class="card-title font-kpmg font-size-44"><router-link class="text-white" :to="{ name: 'Regulators' }" >Sources</router-link></h4>
              <p class="card-text">Regulators, other authorities and industry bodies that we track</p>
            </b-col>
            <b-col md="12" lg="6" class="bg-kpmg-pink text-white py-4">
              <h4 class="card-title font-kpmg font-size-44"><router-link class="text-white" :to="{ name: 'Snapshot' }">Snapshot</router-link></h4>
              <p class="card-text">Design custom reports</p>
            </b-col>
          </b-row>
          <!--
          <div class="row bg-light my-4" v-if="permissions.addPublication && $config.BUILD === 'full'">
            <div class="col-md-6">
                <div class="card mb-4" v-if="permissions.readPanel">
                    <div class="card-body">
                      <h4 class="card-title font-kpmg font-size-44"><router-link :to="{ name: 'RRICAdmin' }" >RIC Admin</router-link></h4>
                      <h6 class="card-subtitle mb-2 text-muted">Administration panel</h6>
                      <p class="card-text">Manage application</p>
                    </div>
                  </div>
            </div>
            <div class="col-md-6">
                <div class="card mb-4" v-if="permissions.addPublication">
                  <div class="card-body">
                    <h4 class="card-title font-kpmg font-size-44"><router-link :to="{ name: 'PublicationAdd' }" >Add news</router-link></h4>
                    <h6 class="card-subtitle mb-2 text-muted">Manually add news</h6>
                    <p class="card-text">Add news not covered by automated feeds</p>
                  </div>
                </div>
            </div>
          </div>
          <div class="row bg-light my-4" v-if="$config.BUILD === 'full'">
            <div class="col-md-6">
                <div class="card mb-4" v-if="permissions.articles">
                    <div class="card-body">
                      <h4 class="card-title font-kpmg font-size-44"><router-link :to="{ name: 'WorkflowArticles' }" >Articles</router-link></h4>
                      <h6 class="card-subtitle mb-2 text-muted">Administration of articles</h6>
                      <p class="card-text">Manage KPMG articles</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card mb-4" v-if="permissions.stats">
                    <div class="card-body">
                      <h4 class="card-title font-kpmg font-size-44"><router-link :to="{ name: 'Stats' }" >Statistics</router-link></h4>
                      <h6 class="card-subtitle mb-2 text-muted">Dashboard</h6>
                      <p class="card-text">Usage statistics</p>
                    </div>
                </div>
            </div>
          </div>
          -->
          <!-- END REG HORIZON -->

          <!-- ESG -->
          <span v-if="$config.BUILD === 'full'">
          <div class="row bg-white my-4">
            <div class="col text-center">
              <h1 class="text-dark kstyle">ESG Regulatory Initiatives</h1>
            </div>
          </div>
          <div class="row bg-light">
            <div class="col-md-6">
                <div class="card mb-4">
                  <div class="card-body">
                    <h4 class="card-title font-kpmg font-size-44"><router-link :to="{ name: 'ImpactInitiatives', query: { p: 'esgReporting' }}" >ESG Reporting</router-link></h4>
                    <p class="card-text">ESG related reporting initiatives</p>
                  </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card mb-4">
                  <div class="card-body">
                    <h4 class="card-title font-kpmg font-size-44"><router-link :to="{ name: 'ImpactInitiatives', query: { p: 'esgTax' }}" >ESG Tax</router-link></h4>
                    <p class="card-text">ESG related taxes and incentives</p>
                  </div>
                </div>
            </div>
          </div>
          </span>
          <!-- END ESG -->

          <!-- MAR -->
          <span v-if="permissions.readMAR">
          <b-row class="bg-kpmg-dark-blue py-4 mt-5 mb-4">
            <b-col class="text-center text-white">
              <h1 class="card-title font-kpmg font-size-44">Market abuse and surveillance</h1>
            </b-col>
          </b-row>
          <b-row class="bg-light">
            <b-col md="4" sm="12">
                <div class="card mb-4">
                  <div class="card-body">
                    <h4 class="card-title font-kpmg font-size-44"><router-link :to="{ name: 'MarDashboard' }" >Dashboard</router-link></h4>
                    <h6 class="card-subtitle mb-2 text-muted">Tracking regulatory activity by risk</h6>
                    <p class="card-text">Market abuse risks and news</p>
                  </div>
                </div>
                <div class="card mb-4">
                  <div class="card-body">
                    <h4 class="card-title font-kpmg font-size-44"><router-link :to="{ name: 'Risks' }" >Risks taxonomy</router-link></h4>
                    <h6 class="card-subtitle mb-2 text-muted">Market abuse risks</h6>
                    <p class="card-text">Market abuse risk taxonomy</p>
                  </div>
                </div>
                <div class="card mb-4" v-if="$config.BUILD === 'full'">
                  <div class="card-body">
                    <h4 class="card-title font-kpmg font-size-44"><router-link :to="{ name: 'Alerts' }" >Alerts</router-link></h4>
                    <h6 class="card-subtitle mb-2 text-muted">Surveillance alerts</h6>
                    <p class="card-text">Logic for detecting market abuse risks</p>
                  </div>
                </div>
                <div class="card mb-4">
                  <div class="card-body">
                    <h4 class="card-title font-kpmg font-size-44"><router-link :to="{ name: 'Extsources' }" >External sources</router-link></h4>
                    <h6 class="card-subtitle mb-2 text-muted">Links</h6>
                    <p class="card-text">Links to external sources supporting risk definitions</p>
                  </div>
                </div>
                <div class="card mb-4">
                  <div class="card-body">
                    <h4 class="card-title font-kpmg font-size-44"><router-link :to="{ name: 'Regbites' }" >Regulatory quotes</router-link></h4>
                    <h6 class="card-subtitle mb-2 text-muted">Source text</h6>
                    <p class="card-text">Relevant fragments of source text</p>
                  </div>
                </div>
                <div class="card mb-4" v-if="permissions.readComm && $config.BUILD === 'full'">
                  <div class="card-body">
                    <h4 class="card-title font-kpmg font-size-44"><router-link :to="{ name: 'Comms' }" >Communications</router-link></h4>
                    <h6 class="card-subtitle mb-2 text-muted">Testing communications surveillance</h6>
                    <p class="card-text">Repository of communications for benchmarking comms surveillance</p>
                  </div>
                </div>
            </b-col>
            <b-col md="4" sm="12">
              <div class="card mb-4">
                  <div class="card-body">
                    <h4 class="card-title font-kpmg font-size-44"><router-link :to="{ name: 'Topic', params: { id: 1 }}" >MAR News</router-link></h4>
                    <h6 class="card-subtitle mb-2 text-muted">Sector news</h6>
                    <p class="card-text">News relevant to market abuse</p>
                    <div v-if="!loadingMAR">
                      <div class="mb-4" v-for="item in mar.news" :key="'mar-new'+item.id">
                        <b-link :to="{ name: 'NewsItem', params: { id: item.id } }">{{ item.name }}</b-link>
                      </div>
                    </div>
                    <div v-else>
                      <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
                    </div>
                  </div>
                </div>
            </b-col>
            <b-col md="4" sm="12">
                <div class="card mb-4">
                  <div class="card-body">
                    <h4 class="card-title font-kpmg font-size-44"><router-link :to="{ name: 'Cases' }" >Cases</router-link></h4>
                    <h6 class="card-subtitle mb-2 text-muted">MAR cases</h6>
                    <p class="card-text">Market abuse case tracker</p>
                    <div v-if="!loadingMAR">
                      <div class="mb-4" v-for="item in mar.cases" :key="'mar-new'+item.id">
                        <b-link :to="{ name: 'Case', params: { id: item.id } }">{{ item.name }}</b-link>
                      </div>
                    </div>
                    <div v-else>
                      <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
                    </div>

                  </div>
                </div>
            </b-col>
          </b-row>
          </span>
          <!-- END MAR -->
        </b-container>
      </b-col>
    </b-row>
    <b-modal
      id="topicSelection"
      size="xl"
      title="Personal Headlines"
      ok-title="Save"
      @ok="saveTopics()"
    >
      <div>
        <b-container>
          <b-row class="text-center mb-4" align-h="center" v-if="loadingTopics">
            <b-col cols="1">
              <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
            </b-col>
          </b-row>
          <b-row class="mb-4" v-if="!loadingTopics">
            <b-col>
              <h1>Select topics to follow</h1>
              <b-form-group label="Topics" class="mb-4">
                <b-form-checkbox-group
                  id="checkbox-group-1"
                  v-model="topicsSelected"
                  :options="topicsOptions"
                  style="column-count: 4;"
                  name="topics"
                  stacked
                ></b-form-checkbox-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import ac from '../libs/accesscontrol'
import staticData from '../libs/static'
import SearchForm from '@/components/SearchForm.vue'
import moment from 'moment'

export default {
  name: 'StartDashboard',
  components: {
    SearchForm
  },
  created: function () {
    if (this.$config.BUILD === 'external' || this.$config.BUILD === 'externalkpmgarc') {
      if (!window.localStorage.getItem('frmCloudExApiKey')) {
        this.$router.push({ name: 'Settings' })
        return
      }
    }
    document.title = "KPMG Regulatory Horizon"
    this.$store.commit('setBrand', 'Regulatory Horizon')
    this.$stat.log({ page: 'start' })
    const favTopics = window.localStorage.getItem('frmCloudFavTopics')
    if (favTopics) {
      this.favTopics = JSON.parse(favTopics)
      this.favTopicIds = favTopics.toString().replace("[", "").replace("]", "")
    }
    this.permissions.articles = ac.can(this.user.acgroups).readAny('articleWorkflowEdit').granted
    this.permissions.addPublication = ac.can(this.user.acgroups).createAny('publication').granted
    this.permissions.readMAR = ac.can(this.user.acgroups).readAny('marrisk').granted
    this.permissions.readComm = ac.can(this.user.acgroups).readAny('comm').granted
    this.permissions.readInsights = ac.can(this.user.acgroups).readAny('article').granted
    this.permissions.readPanel = ac.can(this.user.acgroups).readAny('rricpanel').granted
    this.permissions.stats = ac.can(this.user.acgroups).readAny('stats').granted
    this.permissions.workflow = ac.can(this.user.acgroups).readAny('workflow').granted
    if (this.$config.BUILD === 'full') {
      this.permissions.readMAR = true
      this.permissions.readInsights = true
    }
    this.getStart()
    this.getFavTopics()
    this.newUserCheck()
    this.getTopThree()
    if (this.permissions.readMAR) {
      this.loadMAR()
    }
  },
  data () {
    return {
      articleResults: [],
      topicResults: [],
      publicationResults: [],
      favTopicIds: "",
      favTopics: [],
      numResults: '3',
      userType: 0,
      endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      loading: {
        fav: true,
        startpage: true
      },
      newUser: false,
      permissions: {
        addPublication: false,
        readComm: false,
        readMAR: false,
        readPanel: false,
        workflow: false
      },
      sectors: staticData.sectors,
      startpage: {},
      startPagePersonal: {},
      share: false,
      tags: [],
      dismissCountDown: 0,
      loadingMAR: true,
      mar: {
        news: [],
        cases: []
      },
      loadingTopics: true,
      topics: '',
      topicsOptions: [],
      topicsSelected: []
    }
  },
  computed: {
    articles () {
      return this.startpage.articles
    },
    signedIn () {
      return (this.userState === 'signedIn')
    },
    user: {
      get () {
        return this.$store.state.user
      }
    },
    userState () {
      return this.$store.state.userState
    },
    regTopics () {
      const topics = new Set()
      _.each(this.startPagePersonal["news"], topic => { topics.add(topic.regtopicName) })
      return Array.from(topics)
    }
  },
  methods: {
    personalHeadlines (regTopic) {
      return this.startPagePersonal["news"]
        .filter(headline => headline.regtopicName === regTopic)
    },
    getStart: async function () {
      // Get Headlines
      try {
        let apiName = 'cosmos'
        let path = `/startpage/10/8/10`
        this.$logger.debug(path)
        let response = await this.$Amplify.API.get(apiName, path)
        this.$logger.debug('startpage response: ', response)
        this.startpage = response
        this.loading.startpage = false
      } catch (e) {
        this.$logger.warn('getStart loading error' + e)
      }
    },
    getFavTopics: async function () {
      if (this.favTopics.length > 0) {
        // Get Personal Headlines
        try {
          let apiName = 'cosmos'
          let path = `/startpage/personal`
          let params = {
            body: {
              topics: this.favTopics
            }
          }
          let response = await this.$Amplify.API.post(apiName, path, params)
          this.startPagePersonal = response
          this.loading.fav = false
        } catch (e) {
          this.$logger.warn('personalised page loading error' + e)
        }
      } else {
        this.loading.fav = false
      }
    },
    getTopThree: async function () {
      // Get Top 3
      try {
        const apiName = 'cosmos'
        const topicIdsPath = `/stats/topitems/topics/${this.userType}/5/${this.startDate}/${this.endDate}`
        this.topicResults = await this.$Amplify.API.get(apiName, topicIdsPath)
        const publicationIdsPath = `/stats/topitems/publications/${this.userType}/${this.numResults}/${this.startDate}/${this.endDate}`
        this.publicationResults = await this.$Amplify.API.get(apiName, publicationIdsPath)
        const articleIdsPath = `/stats/topitems/articles/${this.userType}/${this.numResults}/${this.startDate}/${this.endDate}`
        this.articleResults = await this.$Amplify.API.get(apiName, articleIdsPath)
      } catch (e) {
        this.$logger.warn('top 3 loading error' + e)
      }
    },
    loadMAR: async function () {
      this.$logger.debug('loadMAR started')
      this.loadingMAR = true
      try {
        this.mar = await this.$Amplify.API.get('cosmos', '/startpage/mar')
        this.loadingMAR = false
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
    },
    loadTopics: async function () {
      this.$logger.debug('loadTopics started')
      this.loadingTopics = true
      try {
        let apiName = 'cosmos'
        let path = '/topics'
        let response = await this.$Amplify.API.get(apiName, path)
        this.topics = _.sortBy(response, 'name')
        let temp = this.topics.map(x => {
          return { text: x.name, value: x.id }
        })
        _.remove(temp, x => {
          return x.text.includes('ARCHIVED')
        })
        this.topicsOptions = temp
        const options = window.localStorage.getItem('frmCloudFavTopics')
        if (options) {
          this.topicsSelected = JSON.parse(options)
        }
        this.loadingTopics = false
        this.$logger.debug(this.topics)
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
    },
    saveTopics: function () {
      window.localStorage.setItem('frmCloudFavTopics', JSON.stringify(this.topicsSelected))
      const favTopics = window.localStorage.getItem('frmCloudFavTopics')
      if (favTopics) {
        this.favTopics = JSON.parse(favTopics)
        this.favTopicIds = favTopics.toString().replace("[", "").replace("]", "")
      }
      this.getFavTopics()
      this.triggerNotification('success', 'Personal headline topics saved')
      this.newUserCheck()
      // this.$router.go()
    },
    newUserCheck: function () {
      if (this.favTopics.length < 1) {
        this.newUser = true
      } else {
        this.newUser = false
      }
    },
    triggerNotification: function (variant = null, message) {
      this.$bvToast.toast(message, {
        title: 'Notification',
        autoHideDelay: 2000,
        variant: variant,
        solid: true
      })
    }
  },
  watch: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
